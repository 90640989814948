import Store from '@store';
import { useEffect } from 'react';
import { SiblySDK } from '@sibly/sibly-sdk-browser';

import { MonitoringService } from '@services';
import Config from '../../Config';

interface UseNudgesArgs {
  isAuthenticated: boolean;
  path: string;
}

export const useNudges = ({ isAuthenticated, path }: UseNudgesArgs): void => {
  const { myMemberIDsWithRecentlyReleased } = Store;
  useEffect(() => {
    if (!(isAuthenticated && path.includes('dashboard'))) {
      // NOTE: return an empty cleanup function for useEffect to avoid eslint warning
      return () => {};
    }

    const extensionId = Config.nudgeChromeExtensionId;

    const subjects: number[] = JSON.parse(myMemberIDsWithRecentlyReleased);

    const unsubscribeFromSentListeners = subjects.map((subject: number) =>
      SiblySDK.Nudges.onNudgeSentListener({
        extensionId,
        onMessagingUnavailable: (error) => {
          console.log('Chrome extension is not installed or not available', error);
          MonitoringService.addLog({
            message: '[useNudges] Chrome extension is not installed or not available',
            logGroup: 'useNudges',
            logRecordType: 'OnNudgeSentListenerMessagingUnavailableError',
            error,
          });
        },
        onSendMessageError: (error) => {
          MonitoringService.addLog({
            message: '[useNudges] OnNudgeSentListener send message error',
            logGroup: 'useNudges',
            logRecordType: 'OnNudgeSentListenerSendMessageError',
            error,
          });
        },
        onSubscriptionError: (error) => {
          MonitoringService.addLog({
            message: '[useNudges] OnNudgeSentListener subscription error',
            logGroup: 'useNudges',
            logRecordType: 'OnNudgeSentListenerSubscriptionError',
            error,
          });
        },
        subject: `${subject}`,
      }),
    );

    const unsubscribeFromResponseListener = SiblySDK.Nudges.onNudgeResponseListener({
      extensionId,
      onMessagingUnavailable: (error) => {
        console.log('Chrome extension is not installed or not available', error);
        MonitoringService.addLog({
          message: '[useNudges] Chrome extension is not installed or not available',
          logGroup: 'useNudges',
          logRecordType: 'OnNudgeResponseListenerMessagingUnavailableError',
          error,
        });
      },
      onSendMessageError: (error) => {
        MonitoringService.addLog({
          message: '[useNudges] OnNudgeResponseListener send message error',
          logGroup: 'useNudges',
          logRecordType: 'OnNudgeResponseListenerSendMessageError',
          error,
        });
      },
      onSubscriptionError: (error) => {
        MonitoringService.addLog({
          message: '[useNudges] OnNudgeResponseListener subscription error',
          logGroup: 'useNudges',
          logRecordType: 'OnNudgeResponseListenerSubscriptionError',
          error,
        });
      },
    });

    // Initial fetch of unactioned nudges
    SiblySDK.Nudges.fetchUnactionedNudges({
      subjects,
      isInitialFetch: true,
    });

    const nudgeResponseEventsListener = SiblySDK.Nudges.manageNudgeEvents(subjects);

    return () => {
      unsubscribeFromSentListeners.forEach((unsubscribe) => {
        unsubscribe?.();
      });
      unsubscribeFromResponseListener?.();
      nudgeResponseEventsListener?.();
    };
  }, [isAuthenticated, myMemberIDsWithRecentlyReleased, path]);
};
