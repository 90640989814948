import { sharedInMemoryStorage } from '@sibly/sibly-sdk-browser';
import { Environment } from './types';

export const demo: Environment = {
  name: 'development-demo',
  env: 'development',
  mock: false,

  hades: {
    host: 'https://hades.demo.sibly.cloud/v1',
  },

  socket: {
    host: 'wss://97xdbaqpx6.execute-api.us-east-1.amazonaws.com/development-demo',
  },

  wormhole: {
    host: 'https://wormhole.sibly.care/dev',
    apiKey: '3vLJWZoJ4B3zIh7BYvf3was9iviXiFSE8vFQBwQh',
    source: 'Sibly.DashEvents-Dev'
  },

  sendbird: {
    appId: 'E584095B-E78C-4F44-80F9-582062D9AD58',

    bot: {
      id: 'bot',
    },

    coach: {
      id: 'sibly',
      token: '609835f066bf3167ee5aa7cc91e37ca4a3349dbe',
    },
  },

  userSnap: {
    apiKey: 'd7368b35-bd23-451c-93a3-d430984be9b0',
  },

  segment: {
    key: 'yckP6OQHoQtSjB8gv4AEbxrllXnI0GCb',
    track: true,
  },
  // staging key
  split: {
    key: 'k24f71keue19amad2nbqmqrmt4uupqd0l3g5',
  },

  fullStory: {
    orgId: '182P5Q',
    active: false,
  },

  siblySDK: {
    Auth: {
      userPoolId: 'us-west-1_tlijkTiMX',
      userPoolClientId: '7hcre0u3stpedvt0lvlphlvps6',
    },
    transportOptions: {
      region: 'us-west-1',
      endpoint:
        'https://dxokngioijddpaqyt653mh2a7a.appsync-api.us-west-1.amazonaws.com/graphql',
    },
    appId: 'a84a7a19-b6af-4388-850f-eab27dce8d58',
    storage: sharedInMemoryStorage,
  },

  sessionTimeouts: {
    inactivityDetectionTimeout: 30 * 60 * 1000, // 30 minutes
    logoutModalTimeout: 2 * 60 * 1000, // 2 minutes
  },

  nudgeChromeExtensionId: 'eajajgahcoojlanccbimhpfgffdbmonj',
};
