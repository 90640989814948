import { sharedInMemoryStorage } from '@sibly/sibly-sdk-browser';
import { Environment } from './types';

export const prod: Environment = {
  name: 'prod',
  env: 'prod',
  mock: false,

  // TODO: configure all prod keys
  hades: {
    host: 'https://api.hub.sibly.com/v1',
  },

  socket: {
    host: 'wss://di5rmlydea.execute-api.us-east-1.amazonaws.com/prod',
  },

  wormhole: {
    host: 'https://wormhole.sibly.care/prod',
    apiKey: 'bzCnQBTD2W1LaIxSXGwkr8Fj8QDhU0Kh84DsAoCG',
    source: 'Sibly.DashEvents'
  },

  sendbird: {
    appId: '950E545F-B408-414E-80CC-8F90BE6691D2', // prod

    bot: {
      id: 'assistant_bot', // prod
    },

    coach: {
      id: 'sibly-sibly', // prod
      token: '24e166a7bd93121eb733a7545a683f1235601e7c', // prod
    },
  },

  userSnap: {
    apiKey: 'd7368b35-bd23-451c-93a3-d430984be9b0',
  },

  segment: {
    key: 'WqLiEBOKvMjIK3OEg8sbEC0bzeEWgJV0', // prod
    track: true,
  },

  split: {
    key: 'qs4r0jahpphqhv8ro0rdkfghhhpu135a06a8',
  },

  fullStory: {
    orgId: '182P5Q',
    active: true,
  },

  siblySDK: {
    Auth: {
      userPoolId: 'us-west-1_ojTpoRG0j',
      userPoolClientId: '6gsiqkatbrvltpqpinicpli7ev',
    },
    transportOptions: {
      region: 'us-west-1',
      endpoint:
        'https://o7ojpiay4ne2dilehlexjrkrpq.appsync-api.us-west-1.amazonaws.com/graphql',
    },
    appId: 'a84a7a19-b6af-4388-850f-eab27dce8d58',
    storage: sharedInMemoryStorage,
  },

  sessionTimeouts: {
    inactivityDetectionTimeout: 30 * 60 * 1000, // 30 minutes
    logoutModalTimeout: 2 * 60 * 1000, // 2 minutes
  },

  nudgeChromeExtensionId: 'eajajgahcoojlanccbimhpfgffdbmonj',
};
